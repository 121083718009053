@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');


* {
    padding: 0;
    margin: 0;
    font-family: 'Zilla Slab';
    line-height: 1.2;
    color: rgb(38, 38, 39);
    scroll-behavior: smooth;
    text-decoration: none;
    list-style: none;
    box-sizing: border-box;
}

/* Navbar */
.sticky {
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 100;
    box-shadow: 0 0 30px rgb(21 55 44 / 10%);
    background-color: white;
    transition: 0.3ms;
    align-items: center;
}

.wrapper-directive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.wrapper-directive h1 {
    font-size: 24px;
    font-weight: 600;
}
.list-menu {
    display: flex;
}
.list-menu li {
    font-size: 18px;
    font-weight: 400;
    padding-left: 2rem;
}

/* Humberger */
.toggel-menu{
    display: none;
}


/* BERANDA  */
.wrapper-beranda {
    width: 100%;
    margin: auto;
    background-color: rgb(248,245,243);
}
.hero-beranda {
    width: 80%;
    margin: auto;
    height: 90vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgb(248,245,243);
}
.hero-beranda h1 {
    font-size: 34px;
    font-weight: 400;
    color: rgb(0, 0, 0);
    /* padding-bottom: 2rem; */
    background-color: rgb(248,245,243);
    box-sizing: border-box;
}
.box-txt {
    background-color: rgb(248,245,243);
}
.hero-txt{
    width: 90%;
    margin: auto;
    box-sizing: border-box;
    background-color: transparent;
}
.hero-img {
    background-color: rgb(248,245,243);
    box-sizing: border-box;
}
.hero-img img {
    height: 270px;
    align-items: center;
    background-color: rgb(248,245,243);
}

/* FOOTER */
.container-footer {
    box-sizing: border-box;
    background-color: #16382D;
}
.wrapper-footer {
    display: flex;
    margin: auto;
    width: 90%;
    justify-content: flex-start;
    padding: 4rem 4rem 4rem 0rem;
}
.logo-footer {
    width: 50%;
}
.logo-footer h1 {
    color: white;
    font-size: 34px;
    font-weight: 600;
}
.list-footer {
    width: 50%;
    display: flex;
    /* flex-wrap: wrap; */
    box-sizing: border-box;
}
.more-footer {
    width: 50%;
}
.more-footer h2 {
    font-size: 24px;
    font-weight: 600;
    color: white;
}
.touch-footer {
    width: 50%;
}
.touch-footer h2 {
    font-size: 24px;
    font-weight: 600;
    color: white;
}
.feature-footer {
    background-color: #16382D;
}
.feature-footer li {
    padding-top: 1rem;
}
.feature-footer li a {
    font-size: 18px;
    font-weight: 600;
    color: #ABABA3;
}
