@media only screen and (max-width: 876px) {
    /* Navebar */
    .wrapper-directive .list-menu {
        display: none;
    }
    .wrappers-directive {
        border-radius: 20px;
    }
    .lists-menu {
        position: absolute;
        top: 110%;
        left: 70%;
        padding: .5rem 0 .5rem 0;
        border-radius: 6px;
        background-color: #15372cfe;
        box-shadow: 0 0 30px rgb(21 55 44 / 10%);;
    }
    .lists-menu li {
        font-size: 16px;
        font-weight: 600;
        background-color: transparent;
        padding: .7rem 0 .7rem 0;
    }
    .lists-menu li a {
        padding: 2rem 7rem 2rem 2rem;
        color: white;
        background-color: transparent;
    }
    .lists-menu li a:hover {
        color: white;
    }
    .lists-menu li:hover {
        /* background-color: rgb(83, 93, 62); */
        background-color: rgb(77, 85, 63);
    }

    .wrapper-directive h1 {
        font-size: 24px;
        font-weight: 600;
    }

    /* Humberger */
    .toggel-menu{
        overflow: none;
        display: flex;
        flex-direction: column;
        height: 16px;
        border: 0;
        cursor: pointer;
        background-color: white;
        justify-content: space-between;
        border-radius: solid black;
    }
    .toggel-menu :focus {
        outline: none;
    }

    .toggel-menu span {
        display: block;
        width: 20px;
        height: 2px;
        background-color: black;
        border-radius: 20px;
    }

    .hero-beranda {
        width: 90%;
        margin: auto;
    }
    .hero-txt{
        width: 100%;
        margin: auto;
        box-sizing: border-box;
        background-color: transparent;
    }
    .logo-footer {
        width: 100%;
    }
    .more-footer {
        width: 100%;
    }
    .touch-footer {
        width: 100%;
    }
}

@media only screen and (max-width: 590px) {
    /* Navebar */
    .wrapper-directive .list-menu {
        display: none;
    }
    .wrappers-directive {
        
        border-radius: 20px;
    }
    .lists-menu {
        position: absolute;
        top: 110%;
        left: 48%;
        padding: .5rem 0 .5rem 0;
        border-radius: 6px;
        background-color: #15372cfe;
        box-shadow: 0 0 30px rgb(21 55 44 / 10%);
    }
    .lists-menu li {
        font-size: 16px;
        font-weight: 600;
        background-color: transparent;
    }
    .lists-menu li a {
        padding: 2rem 6.6rem 2rem 2rem;
        color: white;
        background-color: transparent;
    }
    .lists-menu li a:hover {
        color: white;
    }
    .lists-menu li:hover {
        background-color: rgb(83, 93, 62);
    }
    
    .wrapper-directive h1 {
        font-size: 24px;
        font-weight: 600;
    }

    /* Humberger */
    .toggel-menu{
        overflow: none;
        display: flex;
        flex-direction: column;
        height: 16px;
        border: 0;
        cursor: pointer;
        background-color: white;
        justify-content: space-between;
        border-radius: solid black;
    }
    .toggel-menu :focus {
        outline: none;
    }

    .toggel-menu span {
        transition: 20s;
        display: block;
        width: 20px;
        height: 2px;
        background-color: black;
        border-radius: 20px;
    }

   .hero-beranda {
    flex-direction: column;
    }
    .hero-beranda h1 {
        text-align: center;
    }
    .hero-txt{
        width: 100%;
        margin: auto;
        box-sizing: border-box;
    }

    .container-footer {
        width: 100%;
        margin: auto;
        box-sizing: border-box;
        background-color: #16382D;
    }
    .wrapper-footer {
        width: 90%;
        margin: auto;
        flex-direction: column;
    }
    .list-footer {
        flex-direction: column;
    }
    .more-footer {
        padding: 2rem 0rem 2rem 0rem;
    }
    .touch-footer {
        padding: 2rem 0rem 0rem 0rem;
    }
}